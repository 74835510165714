.root {
  padding: 20px;
  .row {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: center;
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 550px;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 40px 20px;
      border-radius: 8px;
      .header {
        .title {
          font-family: 'Inter';
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          color: #1a2024;
          text-align: center;
        }
      }
      .date {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        .day {
          font-family: 'Inter';
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #6e8bb7;
          letter-spacing: -0.006em;
        }
        .time {
          font-family: 'Inter';
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #6e8bb7;
          letter-spacing: -0.006em;
        }
      }
      .hr {
        height: 1px;
        border: 16px;
        width: 100%;
        background-color: #e5e9eb;
        margin: 16px 0;
      }
      .body {
        display: flex;
        flex-direction: column;
        .username {
          display: flex;
          justify-content: space-between;
          .clientName {
            text-transform: capitalize;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            font-family: 'Inter';
            color: #48535b;
          }
        }
        .transaction {
          display: flex;
          justify-content: space-between;
          .transactionName {
            display: flex;
            align-items: center;
            .list {
              text-transform: capitalize;
              font-weight: 600;
              font-size: 18px;
              line-height: 21px;
              font-family: 'Inter';
              color: #48535b;
            }
          }
          .amount {
            display: flex;
            align-items: center;
            .listAmount {
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              font-family: 'Inter';
              color: #48535b;
            }
          }
        }
        .statusRow {
          display: flex;
          justify-content: space-between;
          .text {
            text-transform: capitalize;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            font-family: 'Inter';
            color: #48535b;
          }
          .status {
            padding: 4px 22px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            .listStatus {
              line-height: 24px;
              font-size: 14px;
              font-weight: 500;
              font-family: 'Inter';
            }
          }
        }
        .inn {
          display: flex;
          justify-content: space-between;
          .innName {
            text-transform: capitalize;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            font-family: 'Inter';
            color: #48535b;
          }
          .innNumber {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            font-family: 'Inter';
            color: #48535b;
          }
        }
      }
    }
  }
}
