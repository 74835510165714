.root {
    height: 100vh;
    overflow-y: hidden;
    position: relative;
    .container {
      max-width: 100% !important;
      padding-left: 0;
      padding-right: 0;
      height: 100%;
      max-width: 100%;
      .row {
        display: flex;
        height: 100%;
  
        .left {
          flex: 0 0 50%;
          max-width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(0, 103, 244, 0.45) 0%,
            #0067f4 100%
          );
          img {
            max-width: 502px;
            max-height: 406px;
          }
          svg {
            position: absolute;
            top: 20px;
            left: 16px;
          }
          @media (max-width: 576px) {
            display: none;
          }
        }
        .right {
          flex: 0 0 50%;
          @media (max-width: 576px) {
            align-items: center;
            display: flex;
            justify-content: center;
            flex: 0 0 100%;
            max-width: 100vw;
          }
          .formControl {
            display: flex;
            padding: 146px 64px;
            @media (max-width: 576px) {
              flex-direction: column;
              margin: auto 0;
              padding: 0 28px;
            }
            .logo {
              display: none;
              @media (max-width: 576px) {
                display: block;
                margin: 0 auto;
                margin-bottom: 60px;
              }
            }
  
            .row {
              width: 100%;
  
              .errorContent {
                font-style: 12px;
                line-height: 21px;
                font-weight: 400;
                color: #f71111;
              }
              display: flex;
              flex-direction: column;
  
              .title {
                .text {
                  font-weight: 700;
                  font-family: var(--header-text);
                  font-size: 36px;
                  line-height: 48px;
                  color: #001a49;
                  @media (max-width: 576px) {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 28px;
                  }
                }
              }
              .wrongEmail {
                display: flex;
                align-items: center;
                margin-top: 10px;
  
                .errorText {
                  color: #f76659;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 500;
                  margin-left: 8px;
                }
              }
              label {
                margin-top: 40px;
                font-weight: 600;
                font-family: var(--header-text);
                font-size: 16px;
                line-height: 24px;
                color: #1a2024;
                display: flex;
                flex-direction: column;
                @media (max-width: 576px) {
                  font-size: 14px;
                }
                input {
                  margin-top: 8px;
                  padding: 12px 16px;
                  font-size: 14px;
                  line-height: 24px;
                  color: #000;
                  outline: none;
                  border: 1px solid rgba(0, 103, 244, 0.45);
                  box-sizing: border-box;
                  border-radius: 8px;
                  background-color: #fff !important;
                }
                .errorBorder {
                  border: 1px solid #f76659 !important;
                }
              }
              .info {
                margin-top: 24px;
                font-family: var(--header-text);
                font-weight: 600;
                font-size: 16px;
                line-height: 32px;
                color: #6e8bb7;
                letter-spacing: -0.006em;
                @media (max-width: 576px) {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
              .password {
                margin-top: 10px;
              }
              .error {
                padding: 11.5px 16px;
                margin-top: 23px;
                background-color: #fff;
                border: 1px solid #f76659;
                border-radius: 5px;
                .errorText {
                  color: #f76659;
                }
              }
              .btnGroup {
                margin-top: 40px;
                width: 100%;
                .btn {
                  width: 100%;
                  font-family: var(--header-text);
                  padding: 12px;
                  color: #fff;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 32px;
                  background: #0067f4;
                  border-radius: 8px;
                  text-transform: none;
                  @media (max-width: 576px) {
                    height: 48px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  