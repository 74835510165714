.row {
  padding: 113px 200px;
  .title {
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #ccc;
      font-family: var(--header-text);
      margin-left: 10px;
      margin-top: 0;
    }
  }
  .header {
    font-weight: 500;
    width: 100%;
    font-size: 26px;
    line-height: 30.47px;
    font-family: var(--header-text);
    color: #000;
    margin-top: 12px;
  }
  label {
    .errorText {
      font-weight: 400;
      font-family: var(--header-text);
      font-size: 16px;
      line-height: 18.75px;
      color: #f71111;
      margin-top: 46px;
    }
    input {
      padding: 10px 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      font-family: var(--header-text);
      color: #696969;
      outline: none;
      border: 1px solid #000;
      border-radius: 5px;
      max-width: 355px;
      width: 100%;
      margin-top: 10px;
    }
    .mt {
      margin-top: 46px;
    }
    .error {
      border: 1px solid #f71111;
    }
  }
  .text {
    margin-top: 12px;
    color: var(--primary-color);
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    font-family: var(--header-text);
    cursor: pointer;
  }
  .btnGroup {
    margin-top: 29px;
    max-width: 355px;
    .btn {
      width: 100%;
      background: #2a3ff9;
      border-radius: 5px;
      border: 1px solid var(--primary-color);
      font-family: var(--header-text);
      font-size: 22px;
      line-height: 26px;
      font-weight: 400;
      color: #fff;
      padding: 10px;
      text-transform: capitalize;
    }
  }
}
.pt {
  padding: 110px 160px;
}
.successPt {
  padding: 110px 120px;
}
