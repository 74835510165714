.root{
    padding: 40px;
    .row{
        margin: 0 150px;
        background-color: #fff;
        border-radius: 6px;
        .body{
            padding: 20px;
            .btnGroup{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .btn{
                    text-transform: none;
                    background-color: var(--primary-color);
                    color: #fff;
                }
            }
        }
    }
}