.root {
  background-color: #fff;
  border: 1px solid #e5e5e5;
}
.borderNone {
  border: none;
}
.row {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}
.title .text {
  font-family: var(--header-text);
  font-size: 22px;
  line-height: 25.78px;
  font-weight: 700;
  cursor: pointer;
  color: #000;
}
.register a {
  font-family: var(--header-text);
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  cursor: pointer;
}
.login {
  display: flex;
  align-items: center;
}
.btnGroup .btn {
  color: #fff;
  background-color: var(--primary-color);
  font-family: var(--header-text);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 7px 17px;
  border-radius: 5px;
  text-transform: unset;
}
.btnGroup .btn:hover {
  background-color: var(--primary-color);
}
.login .price {
  padding-left: 24px;
  font-weight: 700;
  font-family: var(--header-text);
  font-size: 16px;
  line-height: 19px;
  color: #000;
}
.profile {
  display: flex;
  align-items: center;
  margin-left: 11px;
  cursor: pointer;
}
.profile .dropdown {
  margin-left: 8px;
}
