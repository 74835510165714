.root {
  background-color: #f6f5f3;
  min-height: 80vh;
  padding: 17px 0;

  .row {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin: 0 120px;
    padding: 46px 38px 22px 38px;
    .flexRow {
      display: flex;
      flex-direction: column;
      .header {
        //display: flex;
        .text {
          font-style: 18px;
          font-family: var(--header-text);
          line-height: 21px;
          font-weight: 800;
          color: #ccc;
          margin-right: 43px;
          cursor: pointer;
        }
        .active {
          color: black;
        }
        .right {
          .btn {
            font-family: var(--header-text);
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            padding: 7px 14px;
            text-transform: unset;
            background-color: #fff;
            border-radius: 4px;
            margin-top: 90px;
          }
        }

      }
      .card {
        background-color: #fff;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 30px 26px 21px 18px;
        margin-top: 36px;
        .top {
          display: flex;
          justify-content: space-between;
          .leftCard {
            display: flex;
            flex-direction: column;
            .up {
              .title {
                font-weight: 500;
                font-family: var(--header-text);
                font-size: 20px;
                line-height: 24px;
                color: #000;
              }
            }
            .down {
              display: flex;
              margin-top: 9px;
              .content {
                padding: 5px 15px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #000;
                font-family: var(--header-text);
                background: #f6f5f3;
                border-radius: 4px;
                margin-right: 9px;
              }
            }
          }
          .rightCard {
            display: flex;
            flex-direction: column;
            .price {
              color: #5e9e49;
              font-weight: 700;
              font-size: 18px;
              line-height: 21px;
              font-family: var(--header-text);
            }
            .time {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #000;
              font-family: var(--header-text);
            }
          }
        }
        .middle {
          margin-top: 19px;
          padding-right: 32%;
          .parags {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #000;
            font-family: var(--header-text);
          }
        }
        .down {
          margin-top: 36px;
          display: flex;
          justify-content: space-between;
          .left {
            display: flex;
            .hasChild {
              margin-right: 76px;
              display: flex;
              flex-direction: column;
              .nameText {
                color: #ccc;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                font-family: var(--header-text);
              }
              .text {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #000;
                margin-top: 11px;
              }
            }
          }
          .right {
            .btn {
              font-family: var(--header-text);
              color: var(--primary-color);
              border: 1px solid var(--primary-color);
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              padding: 7px 14px;
              text-transform: unset;
              background-color: #fff;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
