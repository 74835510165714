.step {
  position: relative;
  .payContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    text-align: center;
    border-radius: 10px;
    padding: 8px 0;

    .text {
      color: #fff;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .name {
    position: absolute;
    left: 0;
    width: 100%;
    min-width: 170px;
    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      color: #6e8bb7;
      width: 100%;
    }
    .active {
      color: #0067f4;
    }
  }
  .label {
    position: relative;
    left: 270%;
  }
}
