.root {
    padding: 40px;
    .row {
      margin: 0 150px;
      background-color: #fff;
      border-radius: 6px;
      .body {
        padding: 20px;
        .flex {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          gap: 20px;
          label {
          flex: 0 0 15%;
          white-space: nowrap;
          }
          input {
              flex: 0 0 82%;
              width: 100%;
            padding: 8px 12px;
            border: 1px solid #c0c0c0;
            border-radius: 6px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
          }
        }
        .btnGroup {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .btn {
            text-transform: none;
            background-color: var(--primary-color);
            color: #fff;
          }
        }
      }
    }
    .information {
      margin: 30px 150px 0 150px;
      border-radius: 6px;
      background-color: #fff;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title{
        font-size: 16px;
        line-height: 21px;
        color: #000;
        font-weight: 600;
        margin-bottom: 20px;
        position: relative;
        right: 30px;
      }
      .structure{
        display: flex;
        width: 500px;
        justify-content: space-between;
        padding: 10px 0;
        .name{
          color: var(--primary-color);
          font-weight: 500;
        }
        .text{
          width: 250px;
          text-align: start;
          // white-space: nowrap;
        }
      }
    }
  }
  