.row {
  padding: 113px 150px;
  .title {
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #ccc;
      font-family: var(--header-text);
      margin-left: 10px;
      margin-top: 0;
    }
  }
  .header {
    font-weight: 500;
    font-size: 26px;
    line-height: 30.47px;
    font-family: var(--header-text);
    color: #000;
    margin-top: 12px;
  }
  .content {
    font-weight: 700;
    font-family: var(--header-text);
    font-size: 18px;
    line-height: 21px;
    color: #000;
    margin-top: 24px;
  }
  .text {
    font-weight: 400;
    font-family: var(--header-text);
    font-size: 18px;
    line-height: 21px;
    color: #000;
    margin-top: 30px;
  }
  .getPartner {
    margin-top: 40px;
    a {
      font-weight: 400;
      color: var(--primary-color);
      font-family: var(--header-text);
      font-size: 18px;
      line-height: 21px;
      cursor: pointer;
    }
  }
  .mt {
    margin-top: 22px;
  }
}
