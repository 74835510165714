.filter {
  position: relative;
  .filterRow {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e5e9eb;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 6px;
    cursor: pointer;
    &:hover {
      border-color: rgb(64, 148, 247);
    }
  }
  .filterBody {
    max-width: 480px;
    min-width: 400px;
    // min-height: 200px;
    width: 100%;
    position: absolute;
    top: 48px;
    left: -350px;
    background: #f7f9fb;
    box-shadow: 0px 0px 1px #e5e9eb, 0px 12px 24px rgba(91, 104, 113, 0.24);
    border-radius: 6px;
    border-right: none;
    padding: 24px;
    z-index: 9;
    .selects {
      display: flex;
      gap: 16px;
      .text {
        display: flex;
        // justify-content: center;
        align-items: center;
        max-width: 105px;
        width: 100%;
        .content {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #000;
          font-family: 'Inter';
        }
      }
      select {
        max-width: 188px;
        width: 100%;
        padding: 8px 16px;
        border: 1px solid #e5e9eb;
        box-sizing: border-box;
        border-radius: 6px;
        background-color: #fff;
        outline: none;
      }
      a {
        cursor: pointer;
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(247, 102, 89, 0.15);
        border-radius: 6px;
      }
    }
  }
}
