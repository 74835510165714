.respondents_tabBlock {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  .respondents_lable {
  }
  .respondents__index {
    display: flex;
    align-items: center;
    justify-content: center;
    background: tomato;
    color: #fff;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    font-size: 10px;
  }
}
