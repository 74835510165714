.row {
  padding: 113px 130px;
  .title {
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #ccc;
      font-family: var(--header-text);
      margin-left: 10px;
      margin-top: 0;
    }
  }
  .header {
    font-weight: 500;
    font-size: 26px;
    line-height: 30.47px;
    font-family: var(--header-text);
    color: #000;
    margin-top: 12px;
  }
  .content {
    margin-top: 36px;
    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      font-family: var(--header-text);
      color: #000;
      margin-top: 5px;
      span {
        font-weight: 500;
        color: var(--primary-color);
      }
    }
  }
  .btnGroup {
    margin-top: 36px;
    .btn {
      background-color: #fff;
      border: 1px solid var(--primary-color);
      font-family: var(--header-text);
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      color: var(--primary-color);
      border-radius: 4px;
      padding: 7px 43px;
      text-transform: capitalize;
    }
  }
}
