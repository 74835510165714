input {
  &::placeholder {
    color: rgb(170, 170, 170);
    font-size: 12.5px;
  }
}

/* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
