.root {
  .row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    .card {
      background-color: #fff;
      max-width: 750px;
      width: 100%;
      border-radius: 6px;
      .header {
        box-shadow: inset 0px -1px 0px #dfeeff;
        padding: 16px;
        .title {
          font-family: "Inter";
          color: #1a2024;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
        }
      }
      .body {
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        .label {
          font-family: "Inter";
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #001a49;
          width: 100%;
        }
        input {
          outline: none;
          padding: 8px 12px;
          border-radius: 6px;
          border: 1px solid #e5e9eb;
          font-family: "Inter";
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000;
        }
      }
      .btnGroup {
        padding: 16px;
        box-shadow: inset 0px 1px 0px #eeeeee;
        background-color: #fff;
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        .btnDelete {
          text-transform: none;
          background: rgba(255, 59, 48, 0.1);
          border-radius: 6px;
          padding: 8px 37px;
          font-family: "Inter";
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #ff3b30;
        }
        .btnCancel {
          text-transform: none;
          background: rgba(0, 103, 244, 0.1);
          border-radius: 6px;
          padding: 8px 32px;
          font-family: "Inter";
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #0067f4;
        }
        .btnSave {
          text-transform: none;
          background: #0067f4;
          border-radius: 6px;
          padding: 8px 29px;
          font-family: "Inter";
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #fff;
        }
      }
    }
    .controlRow {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 6px;
      max-width: 750px;
      width: 100%;
      .header {
        box-shadow: inset 0px -1px 0px #dfeeff;
        padding: 16px;
        .title {
          font-family: "Inter";
          color: #1a2024;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
        }
      }
      .body {
        display: flex;
        flex-direction: column;
        .topSide {
          padding: 24px 16px 24px 30px;
          display: flex;
          flex-direction: column;
          .control {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            .left {
              display: flex;
              align-items: center;
              max-width: 300px;
              width: 100%;

              .label {
                font-weight: 600;
                font-family: "Inter";
                font-size: 14px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #000;
                p {
                  white-space: pre;
                }
                span {
                  width: 17px;
                  height: 17px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: 0.2s ease-in;
                  border: 1px solid #e8e8e8;
                  cursor: pointer;
                  margin-right: 4px;
                  &:focus {
                    border: 1px solid #0067f4;
                    color: #0067f4;
                  }
                }
              }
            }
            .right {
              display: flex;
              gap: 14px;
              margin-right: 3rem;
              .status {
                color: #0067f4;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                font-family: "Inter";
              }
            }
          }

          .childSwitch {
            display: flex;
            justify-content: space-between;
            padding: 24px 0;
            gap: 14px;
            .left {
              display: flex;
              align-items: center;
              padding-left: 24px;
              max-width: 300px;
              width: 100%;
              .label {
                font-weight: 600;
                font-family: "Inter";
                font-size: 14px;
                line-height: 24px;
                color: #000;
              }
            }
            .right {
              display: flex;
              margin-right: 3rem;
              gap: 14px;
              .status {
                color: #0067f4;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                font-family: "Inter";
              }
            }
          }
        }
        .btnGroup {
          padding: 16px;
          box-shadow: inset 0px 1px 0px #eeeeee;
          background-color: #fff;
          display: flex;
          gap: 16px;
          justify-content: flex-end;
          .btnDelete {
            text-transform: none;
            background: rgba(255, 59, 48, 0.1);
            border-radius: 6px;
            padding: 8px 37px;
            font-family: "Inter";
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #ff3b30;
          }
          .btnCancel {
            text-transform: none;
            background: rgba(0, 103, 244, 0.1);
            border-radius: 6px;
            padding: 8px 32px;
            font-family: "Inter";
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #0067f4;
          }
          .btnSave {
            text-transform: none;
            background: #0067f4;
            border-radius: 6px;
            padding: 8px 29px;
            font-family: "Inter";
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #fff;
          }
        }
      }
    }
  }
}
