.upload-with-comment {
  box-sizing: border-box;
  position: relative;

  .dropzone {
    width: 100%;
    cursor: pointer;
    position: static;
    height: 164px;
    left: 0px;
    top: 0px;
    border: 1px dashed #d5dadd;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.006em;
      text-align: center;
      margin: 8px 0;
    }

    .button {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // padding: 8px 16px;
      // position: static;
      // background: #FFFFFF;
      // border: 1px solid #DDE2E4;
      // box-sizing: border-box;
      // border-radius: 6px;
      // flex: none;
      // order: 1;
      // flex-grow: 0;

      .btn-title {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.006em;
        text-align: center;
      }
    }
  }

  .files-list {
    padding: 8px 0;

    .file-item {
      display: flex;
      align-items: center;
      margin-top: 16px;

      .file-title {
        flex: none;
        width: 300px;
        margin-right: 12px;
        display: flex;
        align-items: center;

        .filename {
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.006em;
          text-align: left;
          margin-left: 10px;
        }
      }

      .remove-btn {
        width: 50px !important;
        height: 36px;
        border-radius: 6px;
        box-sizing: border-box;
        background-color: #ffefeb;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        cursor: pointer;

        &.save-btn {
          background-color: rgba(64, 148, 247, 1);
          color: #fff;
        }
      }
    }

    .btn-row {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba($color: #fff, $alpha: 0.7);
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
