label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #48535b;
    text-align: left;
    width: 160px;
  }
  
  .formGroup__input {
    width: 424px;
    background-color: #fff;
    border: 1px solid #e5e9eb;
    border-radius: 6px;
    padding: 8px 18px 8px 12px;
    margin-left: 16px;
  }
  
  .formGroup__input:hover {
    border: 1px solid #0067f4;
    /* outline: 1px solid #E5E9EB; */
  }
  
  .formGroup__input:focus {
    background-color: #fff;
    border: 2px solid #0067f4;
    outline: 1px solid #e5e9eb;
  }
  
  .formGroup__input:-webkit-autofill,
  .formGroup__input:-webkit-autofill:hover, 
  .formGroup__input:-webkit-autofill:focus, 
  .formGroup__input:-webkit-autofill:active{
      box-shadow: 0 0 0 30px white inset !important;
  }
  
  select {
      height: 42px;
  }
  
  .root {
    /* width: 600px;
    margin: 0 auto; */
    background-color: #EBF4FF;
    padding: 32px 220px;
    height: 100vh;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    padding: 40px 56px;
  background-color: #fff;
    /* margin: 6rem 7.6rem 17.2rem 0; */
    transition: 0.2s;
    border-radius: 8px;
  }

  .timeRow {
    display: flex;
    padding: 6px 9px;
    background: rgba(0, 103, 244, 0.1);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 250px;
    margin-bottom: 15px;
  }
  
  .time {
    white-space: nowrap;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0067f4;
    font-family: var(--header-text);
    display: flex;
    column-gap: 10px;
  }
  .formGroup__text {
    font-weight: 600;
    margin-bottom: 26px;
  }
  
  .formGroup__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .formGroup__field:not(:last-child) {
    margin-bottom: 16px;
  }
  
  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 72px;
  }
  
  .buttons .formGroup__btn__primary{
    padding: 8px 43px;
    background: #0067F4;
    border-radius: 6px;
    color: #fff;
    border: none;
    font-weight: 500;
    font-size: 14px;
    font-family: Inter;
    line-height: 24px;
    transition: 0.12s;
    text-transform: capitalize;
    height: 40px;
  }
  .buttons .formGroup__btn__primary:hover {
      background: #0067f4;
    }
    .buttons .formGroup__btn {
    border-radius: 6px;
    padding: 8px 43px;
    height: 40px;
    color: rgba(0, 103, 244, 1);
    background-color: #fff;
    border: none;
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;
    font-family: Inter;
    border: 1px solid #e5e9eb;
    transition: 0.12s;
  }
  .buttons .formGroup__btn:hover {
    border: 1px solid #0067f4;
  }