.root {
  background-color: #fff;
  .btnCost {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0 0;
  }
}
.topPanel {
  width: 100%;
  .row {
    padding: 16px 20px 0 16px;
    .box {
      display: flex;
      justify-content: space-between;
      .card {
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 16px 22px;
        display: flex;
        // margin-right: 16px;
        max-width: 330px;
        width: 100%;
        .icon {
          margin-right: 16px;
        }
        .title {
          font-weight: 500;
          font-family: 'Roboto';
          font-size: 18px;
          line-height: 24px;
          color: #000;
          max-width: 200px;
          width: 100%;
          margin-right: 32px;
          white-space: nowrap;
        }
        .price {
          font-weight: 900;
          font-family: 'Roboto';
          font-size: 18px;
          line-height: 21px;
          color: #000;
        }
      }
    }
  }
}

.addCost {
  .btnGroup {
    .btn {
      padding: 8px 12px;
      background: #0067f4;
      border-radius: 8px;
      font-family: 'Inter';
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.006em;
      text-align: center;
      color: #fff;
      text-transform: none;
      svg {
        margin-right: 12px;
      }
    }
  }
}
