.MuiTable-root {
  box-shadow: none;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 16px !important;
}

.card {
  display: flex;
  padding: 20px 12px;
  //   max-width: 208px;
  width: 100%;
  max-height: 120px;
  background-color: #fff;
  border-radius: 12px;
  justify-content: space-between;
  .left {
    max-width: 120px;

    .count {
      font-family: 'Inter';
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #4094f7;
      margin-bottom: 8px;
    }
    .name {
      color: #6e8bb7;
      letter-spacing: -0.32px;
      font-weight: 400;
      font-family: 'Inter';
      font-size: 14px;
      line-height: 20px;
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 56px;
      height: 56px;
      background: rgba(64, 148, 247, 0.15);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
