.root {
  background-color: #fff;

  .row {
    display: flex;
    padding: 18px 0 25px 0;
    .section {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000;
      font-family: var(--header-text);
      margin-right: 91px;
      cursor: pointer;
      &:first-child {
        margin-left: 120px;
      }
    }
    .active {
      font-weight: 700;
    }
  }
}
